<template>
  <div class="paiements adjust_width">
    <title_cat>Paiements</title_cat>
    
    <div class="bloc">
      <svg viewBox="0 0 60 25" xmlns="http://www.w3.org/2000/svg" width="60" height="25" class="UserLogo variant-- "><title>Stripe logo</title><path fill="var(--userLogoColor, #0A2540)" d="M59.64 14.28h-8.06c.19 1.93 1.6 2.55 3.2 2.55 1.64 0 2.96-.37 4.05-.95v3.32a8.33 8.33 0 0 1-4.56 1.1c-4.01 0-6.83-2.5-6.83-7.48 0-4.19 2.39-7.52 6.3-7.52 3.92 0 5.96 3.28 5.96 7.5 0 .4-.04 1.26-.06 1.48zm-5.92-5.62c-1.03 0-2.17.73-2.17 2.58h4.25c0-1.85-1.07-2.58-2.08-2.58zM40.95 20.3c-1.44 0-2.32-.6-2.9-1.04l-.02 4.63-4.12.87V5.57h3.76l.08 1.02a4.7 4.7 0 0 1 3.23-1.29c2.9 0 5.62 2.6 5.62 7.4 0 5.23-2.7 7.6-5.65 7.6zM40 8.95c-.95 0-1.54.34-1.97.81l.02 6.12c.4.44.98.78 1.95.78 1.52 0 2.54-1.65 2.54-3.87 0-2.15-1.04-3.84-2.54-3.84zM28.24 5.57h4.13v14.44h-4.13V5.57zm0-4.7L32.37 0v3.36l-4.13.88V.88zm-4.32 9.35v9.79H19.8V5.57h3.7l.12 1.22c1-1.77 3.07-1.41 3.62-1.22v3.79c-.52-.17-2.29-.43-3.32.86zm-8.55 4.72c0 2.43 2.6 1.68 3.12 1.46v3.36c-.55.3-1.54.54-2.89.54a4.15 4.15 0 0 1-4.27-4.24l.01-13.17 4.02-.86v3.54h3.14V9.1h-3.13v5.85zm-4.91.7c0 2.97-2.31 4.66-5.73 4.66a11.2 11.2 0 0 1-4.46-.93v-3.93c1.38.75 3.1 1.31 4.46 1.31.92 0 1.53-.24 1.53-1C6.26 13.77 0 14.51 0 9.95 0 7.04 2.28 5.3 5.62 5.3c1.36 0 2.72.2 4.09.75v3.88a9.23 9.23 0 0 0-4.1-1.06c-.86 0-1.44.25-1.44.9 0 1.85 6.29.97 6.29 5.88z" fill-rule="evenodd"></path></svg><div class="i">
        <div class="left">
          <div class="info">Activer votre compte Stripe pour recevoir le paiement de vos ventes</div>
          <div class="check">
            <check :check="account">Initialisation du compte</check>
            <check :check="check">Vérification des informations par Stripe</check>
            <p class="past_due" v-if="past_due">{{ past_due }}</p>
            <check :check="payouts_enabled">Compte activé</check>
            
<!--            <check :check="infos_ok">Remplir les informations nécessaires</check>-->
            
          </div>
        </div>
        <div class="right">
          <button_plus txt="Configurer Stripe" txt_load="Ouverture de la fenêtre" color="stripe" :disabled="button_disable" @success="account_link_init"></button_plus>
        </div>
      </div>
    </div>


    
    <div class="tableau_scroll">
      
    <div class="tableau " :class="{ load: load === false } ">
      <div class="ligne head" style="grid-template-columns: 10% 15% 15% 15% 35% 10%">
        <div class="e">Date</div>
        <div class="e avant_violet">Paiement</div>
        <div class="e avant_blue">Frais</div>
        <div class="e avant_red">Gain</div>
        <div class="e">Client</div>
        <div class="e">Etat</div>
      </div>

      <div class="ligne zoom_color white2" style="grid-template-columns: 10% 15% 15% 15% 35% 10%">
        <div class="e c not_important"></div>
        <div class="e avant_violet"><number :value="calc_col('price')" round="formatNumberdecimal" :euro="true"></number></div>
        <div class="e avant_blue not_important"><number :value="calc_col('pay', 'fee') / 100" round="formatNumberdecimal" :euro="true"></number></div>
        <div class="e avant_red green"><number :value="calc_col('price') - (calc_col('pay', 'fee') / 100)" round="formatNumberdecimal" :euro="true"></number></div>
        <div class="e not_important capitalize"></div>
        
      </div>
      
      <div class="ligne background2 order" style="grid-template-columns: 10% 15% 15% 15% 35% 10%" v-for="(n, k) in commandes" :key="k" :class="{ disabled: n.pay.status !== 'succeeded' || n.canceled }">
        <div class="e c not_important">{{ $dayjs(n.at).format('DD/MM') }} <span>{{ $dayjs(n.at).format('HH:mm') }}</span></div>
        <div class="e avant_violet"><number :value="n.price" round="formatNumberdecimal" :euro="true"></number></div>
        <div class="e avant_blue not_important"><number :value="n.pay.fee / 100" round="formatNumberdecimal" :euro="true"></number></div>
        <div class="e avant_red green"><number :value="n.price - (n.pay.fee / 100)" round="formatNumberdecimal" :euro="true"></number></div>
        <div class="e not_important capitalize">{{ n.nom }} {{ n.prenom }}</div>
        
        <div class="e" v-if="n.canceled">
          <el-tooltip :content="'Remboursé: ' + n.canceled.type">
            <svg fill="#555" aria-hidden="true" class="SVGInline-svg SVGInline--cleaned-svg SVG-svg Icon-svg Icon--refund-svg Icon-color-svg Icon-color--gray500-svg" height="30" width="30" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M10.5 5a5 5 0 0 1 0 10 1 1 0 0 1 0-2 3 3 0 0 0 0-6l-6.586-.007L6.45 9.528a1 1 0 0 1-1.414 1.414L.793 6.7a.997.997 0 0 1 0-1.414l4.243-4.243A1 1 0 0 1 6.45 2.457L3.914 4.993z" fill-rule="evenodd"></path></svg>
          </el-tooltip>
        </div>
        
        <div class="e" v-else-if="n.pay.status === 'succeeded'"><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="36" height="36" viewBox="0 0 48 48" style=" fill:#000000;"><path fill="#c8e6c9" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path><path fill="#4caf50" d="M34.586,14.586l-13.57,13.586l-5.602-5.586l-2.828,2.828l8.434,8.414l16.395-16.414L34.586,14.586z"></path></svg></div>
        
        <div class="e" v-else-if="n.pay.status === 'requires_payment_method'">
          <el-tooltip content="Le client n'a pas renseigné sa carte">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                 width="30" height="30"
                 viewBox="0 0 48 48"
                 style=" fill:#000000;"><path fill="#2196F3" d="M37,40H11l-6,6V12c0-3.3,2.7-6,6-6h26c3.3,0,6,2.7,6,6v22C43,37.3,40.3,40,37,40z"></path><path fill="#FFF" d="M22 20H26V31H22zM24 13A2 2 0 1 0 24 17 2 2 0 1 0 24 13z"></path></svg>
          </el-tooltip>
        </div>
        <div class="e" v-else>
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
               width="36" height="36"
               viewBox="0 0 48 48"
               style=" fill:#000000;"><path fill="#f44336" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path><path fill="#fff" d="M29.656,15.516l2.828,2.828l-14.14,14.14l-2.828-2.828L29.656,15.516z"></path><path fill="#fff" d="M32.484,29.656l-2.828,2.828l-14.14-14.14l2.828-2.828L32.484,29.656z"></path></svg>
        </div>
      </div>
    </div>

    </div>
    
  </div>
</template>

<script>
import check from "@/components/global/check";
export default {
  name: "paiements",
  data() {
    return {
      account: 'load',
      check: 'load',
      payouts_enabled: 'load',
      
      past_due: false,
      
      button_disable: true,
      
      interval_check: null,
      
      load: false,
      
      commandes: {}
    }
  },
  components: { check },
  methods: {
    account_link_init() {
      this.$http.post('/bo/stripe/account_link_init/', { boutique_id: this.$store.state.user.user.boutique } ).then((s) => {
        // window.open( s.data.url, '_blank' );
        location.href = s.data.url
        console.log(s)
        console.log(s.data.url)
      })
    },
    
    calc_col(key, key2 = false) {
      let r = 0
      for(let n in this.commandes) {
        if(this.commandes[n][key] && this.commandes[n].pay.status === 'succeeded' && !this.commandes[n].canceled && key2 === false)
          r += parseFloat(this.commandes[n][key])
        else if(this.commandes[n][key][key2] && this.commandes[n].pay.status === 'succeeded' && !this.commandes[n].canceled && key2 !== false)
          r += parseFloat(this.commandes[n][key][key2])
      }
      return r
    },
    
    account_check() {
      this.$http.get('/bo/stripe/check_account/' + this.$store.state.user.user.boutique).then((s) => {
        let stripe_return = s.data
        console.log(s.data)
        this.button_disable = false
        this.account = true
        if(stripe_return.payouts_enabled === false) {
          if(stripe_return.requirements.disabled_reason === "requirements.pending_verification") {
            this.check = 'load'
            this.payouts_enabled = false
            this.button_disable = true
            // this.infos_ok = true
          }
          else if(stripe_return.requirements.disabled_reason === "requirements.past_due") {
            this.past_due = "Stripe a besoin de plus d'informations, cliquez sur le bouton pour les compléter."
            this.payouts_enabled = false
          }
        }
        else {
          this.payouts_enabled = true
          this.check = true
          this.button_disable = false
          this.$store.dispatch("boutique_bo/sync", this.$store.state.user.user.boutique);
        }
      }).catch(() => {
        this.account = false
        this.check = false
        this.payouts_enabled = false
        this.button_disable = false
      })
    }
  },
  created() {
    let self = this
    this.account_check()
    this.interval_check = setInterval(() => {
      self.account_check()
    }, 5000)
    
    this.$http.get('/bo/boutique/commandes/pay').then((data) => {
      this.commandes = data.data
      this.load = true
    })
    
  },
  beforeDestroy() {
    clearInterval(this.interval_check)
  }
}
</script>

<style scoped>

  .bloc { font-size: 16px; font-weight: 400; border: 1px solid #eee; padding: 16px 24px; }
  .bloc .info { padding: 16px 0 16px 0; }
  .bloc .i { display: flex; justify-content: space-between; align-items: flex-start; flex-direction: column }
  .bloc .i .left { margin-bottom: 20px; }
  
  .past_due { font-size: 14px; font-weight: 500; display: inline-block; background: #e1e2ef; color: #6772e5; padding: 0 8px; margin: 0 0 16px 0 }
  /*.bloc .i { display: flex; }*/
  
  .tableau_scroll { margin-bottom: 32px; }
  .tableau { margin-top: 36px; min-width: 800px; padding-top: 6px; }



  @media only screen and (max-width: 800px) {
    .tableau_scroll { overflow-x: auto; }
    .tableau .ligne .e { font-size: 16px }
  }
  @media only screen and (min-width: 800px) {
    .tableau_scroll { overflow-x: visible; }
    .bloc .i { flex-direction: row }
    .bloc .i .left { margin-bottom: 0; }
  }

</style>